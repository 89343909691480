import { FunctionComponent } from "react";
import styles from "./BlogArticles.module.css";

const BlogArticles: FunctionComponent = () => {
  return (
    <div className={styles.grid}>
      <div className={styles.header}>
        <h1 className={styles.title}>Apprenez, explorez et grandissez avec nous !</h1>
        <p className={styles.description}>
          Plongez dans le monde fascinant de l'IA et des technologies connexes à travers notre blog. Découvrez des articles captivants qui explorent les sujets les plus pertinents, des modèles de langage à la protection de la vie privée. Restez à la pointe de l'innovation et inspirez-vous des idées novatrices présentées dans notre blog.
        </p>
      </div>
      <div className={styles.card}>
        <div className={styles.articleContent}>
          <h2 className={styles.articleTitle}>LLM ?</h2>
          <p className={styles.articleText}>
            Découvrez les modèles de langage et leurs applications révolutionnaires dans le traitement du langage naturel.
          </p>
          <div className={styles.authorInfo}>
            <img className={styles.profileImage} src="/placeholder-image-12@2x.png" alt="Author 1" />
            <span className={styles.date}>12 mai 2024</span>
          </div>
        </div>
        <img
          className={styles.articleImage}
          src="/blog1.png"
          alt="Article 1"
        />
      </div>
      <div className={styles.card}>
        <div className={styles.articleContent}>
          <h2 className={styles.articleTitle}>Pourquoi nettoyer ses données?</h2>
          <p className={styles.articleText}>
            Apprenez l'importance du nettoyage des données et son impact sur la qualité des analyses et des modèles d'IA.
          </p>
          <div className={styles.authorInfo}>
            <img className={styles.profileImage} src="/placeholder-image-12@2x.png" alt="Author 2" />
            <span className={styles.date}>10 mai 2024</span>
          </div>
        </div>
        <img
          className={styles.articleImage}
          src="/placeholder-image12@2x.png"
          alt="Article 2"
        />
      </div>
      <div className={styles.card}>
        <div className={styles.articleContent}>
          <h2 className={styles.articleTitle}>Une bonne infrastructure de données.. À quoi ça sert?</h2>
          <p className={styles.articleText}>
            Explorez les avantages d'une infrastructure de données solide et son rôle crucial dans la réussite des projets d'IA.
          </p>
          <div className={styles.authorInfo}>
            <img className={styles.profileImage} src="/placeholder-image-12@2x.png" alt="Author 3" />
            <span className={styles.date}>8 mai 2024</span>
          </div>
        </div>
        <img
          className={styles.articleImage}
          src="/placeholder-image13@2x.png"
          alt="Article 3"
        />
      </div>
      <div className={styles.card}>
        <div className={styles.articleContent}>
          <h2 className={styles.articleTitle}>Agents IA?</h2>
          <p className={styles.articleText}>
            Plongez dans le monde des agents IA et découvrez leurs capacités d'apprentissage et d'adaptation.
          </p>
          <div className={styles.authorInfo}>
            <img className={styles.profileImage} src="/placeholder-image-12@2x.png" alt="Author 4" />
            <span className={styles.date}>6 mai 2024</span>
          </div>
        </div>
        <img
          className={styles.articleImage}
          src="/agents.png"
          alt="Article 4"
        />
      </div>
      <div className={styles.card}>
        <div className={styles.articleContent}>
          <h2 className={styles.articleTitle}>La vie privée de vos données... C'est également la votre.</h2>
          <p className={styles.articleText}>
            Comprenez l'importance de la protection de la vie privée dans le contexte de l'IA et les solutions pour la garantir.
          </p>
          <div className={styles.authorInfo}>
            <img className={styles.profileImage} src="/placeholder-image-12@2x.png" alt="Author 5" />
            <span className={styles.date}>4 mai 2024</span>
          </div>
        </div>
        <img
          className={styles.articleImage}
          src="/blog2.png"
          alt="Article 5"
        />
      </div>
      <div className={styles.card}>
        <div className={styles.articleContent}>
          <h2 className={styles.articleTitle}>Une nouvelle ère!</h2>
          <p className={styles.articleText}>
            Découvrez les perspectives d'avenir de l'IA et son impact sur notre société.
          </p>
          <div className={styles.authorInfo}>
            <img className={styles.profileImage} src="/placeholder-image-12@2x.png" alt="Author 6" />
            <span className={styles.date}>2 mai 2024</span>
          </div>
        </div>
        <img
          className={styles.articleImage}
          src="/blog3.png"
          alt="Article 6"
        />
      </div>
    </div>
  );
};

export default BlogArticles;
