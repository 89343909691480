import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Mentoring from "./pages/Mentoring";
import Chatbots from "./pages/Chatbots";
import OtherServices from "./pages/OtherServices";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog"; // Import Blog page
import Arty from "./components/Arty";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import './global.css';



function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Home";
        metaDescription = "Welcome to our home page";
        break;
      case "/mentoring":
        title = "Mentoring Services";
        metaDescription = "Explore our mentoring services";
        break;
      case "/chatbots":
        title = "Chatbot Solutions";
        metaDescription = "Discover our chatbot solutions";
        break;
      case "/other-services":
        title = "Other Services";
        metaDescription = "Check out our various services";
        break;
      case "/about-us":
        title = "About Us";
        metaDescription = "Learn more about our team and mission";
        break;
      case "/contact":
        title = "Contact Us";
        metaDescription = "Get in touch with us for more information";
        break;
      case "/blog":
        title = "Blog";
        metaDescription = "Read our latest articles";
        break;
      case "/terms-of-use": // Route pour les conditions d'utilisation
        title = "Conditions d'utilisation";
        metaDescription = "Consultez nos termes et conditions d'utilisation.";
        break;
      case "/privacy-policy": // Route pour la politique de confidentialité
        title = "Politique de confidentialité";
        metaDescription = "Découvrez comment nous protégeons vos données personnelles.";
        break;
      // Ajoute d'autres chemins si nécessaire
      default:
        title = "Alpaga - Votre partenaire en IA";
        metaDescription = "Bienvenue chez Alpaga, votre partenaire en intelligence artificielle.";
        break;
    }

    document.title = title;

    const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
      'head > meta[name="description"]'
    );
    if (metaDescriptionTag) {
      metaDescriptionTag.content = metaDescription;
    }
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mentoring" element={<Mentoring />} />
        <Route path="/chatbots" element={<Chatbots />} />
        <Route path="/other-services" element={<OtherServices />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} /> {/* Route pour le blog */}
        <Route path="/terms-of-use" element={<TermsOfUse />} /> {/* Route pour Terms of Use */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Route pour Privacy Policy */}
      </Routes>
      <Arty /> {/* Inclusion du composant Arty */}
    </>
  );
}

export default App;
