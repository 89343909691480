import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./UnderConstruction.module.css";

const UnderConstruction: FunctionComponent = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'

  const handleSubscribe = async () => {
    if (!email) {
      setMessageType('error');
      setMessage('Veuillez entrer un email valide.');
      return;
    }

    try {
      const response = await fetch('https://newsletter-backend-service-732usxrs2q-uc.a.run.app/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessageType('success');
        setMessage('Email enregistré avec succès !');
        setEmail('');
      } else {
        const errorMessage = await response.text();
        setMessageType('error');
        setMessage(errorMessage);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessageType('error');
      setMessage('Erreur lors de l\'enregistrement de l\'email.');
    }
  };

  return (
    <div className={styles.underConstruction}>
      <div className={styles.messageCard}>
        <img src="/Blogue_construction.png" alt="En construction" className={styles.image} />
        <div className={styles.content}>
          <h2 className={styles.title}>En construction</h2>
          <p className={styles.text}>
            Notre section blogue est présentement en construction. Abonnez-vous à notre infolettre pour rester au courant des dernières nouvelles.
          </p>
          <div className={styles.form}>
            <input
              className={styles.input}
              placeholder="Votre Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className={styles.subscribeButton} onClick={handleSubscribe}>
              S'abonner
            </button>
          </div>
          {message && (
            <div className={messageType === 'success' ? styles.successMessage : styles.errorMessage}>
              {message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnderConstruction;
