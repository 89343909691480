import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Layout3.module.css";

const Layout3: FunctionComponent = () => {
  const navigate = useNavigate();

  const onStyleSecondarySmallFalseClick = useCallback(() => {
    navigate("/other-services");
  }, [navigate]);

  return (
    <div className={styles.layout207}>
      <div className={styles.container}>
        <img
          className={styles.placeholderImageIcon}
          alt=""
          src="/everyone.png"
        />
        <div className={styles.content}>
          <div className={styles.content1}>
            <div className={styles.sectionTitle}>
              <div className={styles.subheading}>Accessible</div>
              <div className={styles.content2}>
                <h1 className={styles.heading}>
                  Rendre les Solutions en IA Accessibles à Tous
                </h1>
                <div className={styles.text}>
                Nous nous engageons à démocratiser l'accès à l'IA et aux sciences des données pour les non-experts. Nos services incluent le coaching en IA générative, le développement de chatbots intelligents, ainsi que la création de tableaux de bord et l'analyse et le nettoyage des données. Nous offrons des solutions adaptées à vos besoins pour vous aider à tirer pleinement parti des technologies de l'IA.
                </div>
              </div>
            </div>
            <div className={styles.list}>
              <div className={styles.listItem}>
                <img
                  className={styles.iconRelume}
                  alt=""
                  src="/icon--relume-1.svg"
                />
                <div className={styles.loremIpsumDolor}>
                  Coaching en IA générative
                </div>
              </div>
              <div className={styles.listItem1}>
                <img
                  className={styles.iconRelume1}
                  alt=""
                  src="/icon--relume-1.svg"
                />
                <div className={styles.loremIpsumDolor1}>
                  Développement de chatbot
                </div>
              </div>
              <div className={styles.listItem2}>
                <img
                  className={styles.iconRelume2}
                  alt=""
                  src="/icon--relume-1.svg"
                />
                <div className={styles.loremIpsumDolor2}>
                Évaluation et recommandations stratégique
                </div>
              </div>
            </div>
          </div>
          <div className={styles.actions}>
            <button
              className={styles.stylesecondarySmallfalse}
              onClick={onStyleSecondarySmallFalseClick}
            >
              <div className={styles.button}>En savoir plus</div>
            </button>
            <div className={styles.stylelinkSmallfalseDark}>
              <div className={styles.button1}>S'inscrire</div>
              <img
                className={styles.iconChevronRight}
                alt=""
                src="/icon--chevronright.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout3;
